<template>
  <div class="bg-white w-full py-10">
    <div class="px-10 sm:flex-shrink-0 max-w-sm text-center mx-auto">
      <MultiTasking />
    </div>
    <div class="sm:flex-shrink-0 max-w-sm text-center mx-auto">
      <h2 class="text-3xl mt-6">
        Manage your custom tests
      </h2>
      <p class="text-gray-600 mb-6">
        Create a custom test for your candidates.
      </p>
      <CreateEditTest>
        <template v-slot:trigger="{modalOpen}">
          <BaseButton @click="modalOpen = true">
            Create Custom Test
          </BaseButton>
        </template>
        <template v-slot:title>
          Create Custom Test
        </template>
      </CreateEditTest>
    </div>
  </div>
</template>

<script>
import CreateEditTest from '@components/TestBuilder/CreateEditTest'
import MultiTasking from '@components/Vectors/MultiTasking'

export default {
  components: {
    MultiTasking,
    CreateEditTest
  }
}
</script>
