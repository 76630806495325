<template>
  <transition
    appear
    enter-active-class="transition-all duration-300 ease-out"
    leave-active-class="transition-all duration-300 ease-in"
    enter-class="opacity-0 transform translate-x-5"
    leave-class="opacity-100 transform translate-x-0"
    enter-to-class="opacity-100 transform translate-x-0"
    leave-to-class="opacity-0 transform translate-x-5"
  >
    <p class="absolute text-red-500 text-xs mt-1 transition-all duration-300 ease-out">
      <slot />
    </p>
  </transition>
</template>

<script>
export default {}
</script>
