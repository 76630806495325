<template>
  <form
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <label class="block">
      <span class="text-gray-800 text-sm">Test name</span>
      <input
        v-model="$v.form.name.$model"
        type="text"
        class="form-input mt-1 block w-full"
        placeholder="e.g. Industry Knowledge, Commercial Regulation"
      >
      <ErrorsInline v-if="hasErrors">
        <span v-if="!$v.form.name.required">This field is required.</span>
      </ErrorsInline>
    </label>

    <label class="block mt-6">
      <span class="text-gray-800 text-sm">Test duration in minutes (optional)</span>
      <input
        v-model.number="$v.form.minutes.$model"
        type="text"
        class="form-input mt-1 block w-full"
      >
    </label>

    <section class="mx-auto text-right relative mt-8 h-12">
      <BaseButton
        :loading="loading"
        :disabled="loading"
        @click="submit"
      >
        Create Custom Test
      </BaseButton>
    </section>
  </form>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'

import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import organisationExamsApi from '@api/organisationExams'

export default {
  components: {
    ErrorsInline
  },

  mixins: [validationMixin],

  props: {
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      hasErrors: false,

      form: {
        name: null,
        minutes: null
      }
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    /**
     * @return {Boolean}
     */
    canSubmit() {
      return this.form.name
    }
  },

  validations: {
    form: {
      name: {
        required
      },
      minutes: {}
    }
  },

  created() {
    if (this.exam) {
      Object.assign(this.form, this.exam)
    }
  },

  methods: {
    /**
     * Submit the form
     */
    submit() {
      this.loading = true
      this.errorMessages = []

      this.$v.form.$touch()
      this.hasErrors = this.$v.form.$anyError

      if (this.hasErrors) {
        this.loading = false
        return
      }

      if (this.$route.params.exam) {
        this.updateExam()
      } else {
        this.createExam()
      }
    },

    createExam() {
      const exam = {
        ...this.form,
        instruction_slug: 'choice'
      }

      return organisationExamsApi.createExam(this.organisationId, exam)
        .then(exam => {
          this.$emit('close')

          if (!this.$route.params.exam) {
            this.$router.push({ name: 'test-builder-edit', params: { exam: exam.uuid } })
          }
        })
        .catch(error => {
          throw error
        })
    },

    updateExam() {
      return organisationExamsApi.updateExam(this.organisationId, this.exam.uuid, this.form)
        .then(exam => {
          this.$emit('close')
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
