<template>
  <BaseContainer>
    <div v-if="state === states.READY">
      <BasePageHeader>
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Custom Tests</BasePageHeading>
        </div>
        <BasePageActions>
          <CreateEditTest>
            <template v-slot:trigger="{modalOpen}">
              <BaseButton @click="modalOpen = true">
                Create Custom Test
              </BaseButton>
            </template>
            <template v-slot:title>
              Create Custom Test
            </template>
          </CreateEditTest>
        </BasePageActions>
      </BasePageHeader>

      <BaseWrapper v-if="exams.length > 0">
        <BaseTable>
          <BaseTHead>
            <tr>
              <BaseTHeadTh>
                Name
              </BaseTHeadTh>
              <BaseTHeadTh>
                Time
              </BaseTHeadTh>
              <BaseTHeadTh align="right">
                Created
              </BaseTHeadTh>
              <BaseTHeadTh align="right">
                Actions
              </BaseTHeadTh>
            </tr>
          </BaseTHead>
          <BaseTBody>
            <tr
              v-for="exam in exams"
              :key="exam.uuid"
            >
              <BaseTBodyTh width="30%">
                <router-link :to="{ name: 'test-builder-edit', params: { exam: exam.uuid } }">
                  {{ exam.name }}
                </router-link>
              </BaseTBodyTh>
              <BaseTBodyTd>
                <div
                  v-if="exam.minutes"
                  class="inline-flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="ml-1">{{ exam.minutes }} minutes</span>
                </div>
                <div v-else>
                  Not timed
                </div>
              </BaseTBodyTd>
              <BaseTBodyTd align="right">
                {{ formatDate(exam.createdAt) }}
              </BaseTBodyTd>
              <BaseTBodyTd
                align="right"
                width="30%"
              >
                <div class="inline-flex space-x-2 justify-items-end">
                  <BaseButton
                    variant="salmon"
                    :to="{ name: 'test-builder-edit', params: { exam: exam.uuid } }"
                  >
                    Edit
                  </BaseButton>
                </div>
              </BaseTBodyTd>
            </tr>
          </BaseTBody>
        </BaseTable>
      </BaseWrapper>
      <BaseWrapper v-else>
        <TestsEmpty />
      </BaseWrapper>
    </div>
    <div v-else-if="state === states.LOADING">
      <BasePageHeader>
        <div>
          <BasePageBreadcrumbs></BasePageBreadcrumbs>
          <BasePageHeading></BasePageHeading>
        </div>
      </BasePageHeader>
      <Loader />
    </div>
    <div v-else-if="state === states.ERROR">
      <BaseErrorBlock />
    </div>
  </BaseContainer>
</template>

<script>
import CreateEditTest from '@components/TestBuilder/CreateEditTest'
import TestsEmpty from '@components/TestBuilder/TestsEmpty'
import organisationExamsApi from '@api/organisationExams'
import Loader from '@components/Loader'

import states from '@api/states'
import { formatDate } from '@utils/formatDate'
import { mapGetters } from 'vuex'

export default {
  page: {
    title: 'Custom Tests'
  },

  components: {
    CreateEditTest,
    TestsEmpty,
    Loader
  },

  data() {
    return {
      states,
      formatDate,
      loading: false,
      error: null,
      exams: []
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (this.loading) {
        return states.LOADING
      }

      return states.READY
    }
  },

  created() {
    this.fetchExams()
  },

  methods: {
    fetchExams() {
      this.loading = true

      return organisationExamsApi.exams(this.organisationId)
        .then(exams => {
          this.loading = false
          this.exams = exams.filter(exam => !exam.archivedAt)
        })
        .catch(error => {
          this.loading = false
          this.error = error
          throw error
        })
    }
  }
}
</script>
