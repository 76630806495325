import { format } from 'date-fns'

/**
 * @return {String}
 */
export const formatDate = date => {
  return format(new Date(date), 'd MMM yyyy')
}

/**
 * @return {String}
 */
export const formatDateTime = dateTime => {
  return format(new Date(dateTime), 'd MMM yyyy H.mm')
}
