<template>
  <div>
    <div @click="modalOpen = true">
      <slot
        name="trigger"
        :modalOpen="modalOpen"
      >
        <TooltipButton class="hover:text-secondary">
          <template
            v-slot:content
          >
            <BaseButton
              variant="salmon"
            >
              <template slot="iconMiddle">
                <Icon
                  view-box="0 0 24 24"
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <Edit fill="none" />
                </Icon>
              </template>
            </BaseButton>
          </template>
          <template v-slot:tooltip>
            Edit test
          </template>
        </TooltipButton>
      </slot>
    </div>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            <slot
              name="title"
            >
              Edit test
            </slot>
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <EditTestForm
        v-if="modalOpen"
        :exam="exam"
        @close="examUpdated"
      />
    </Modal>
  </div>
</template>

<script>
import TooltipButton from '@components/TooltipButton'
import Edit from '@components/Icons/Edit'
import EditTestForm from '@components/TestBuilder/EditTestForm'
import Icon from '@components/Icons/Icon'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    TooltipButton,
    Edit,
    EditTestForm,
    Icon,
    Modal,
    Plus
  },

  props: {
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  watch: {
    /**
     * Watch for the modal closing and reset the sitting
     *
     * @param {Boolean}
     */
    modalOpen(value) {
      if (value === false) {
        this.sitting = null
      }
    }
  },

  methods: {
    examUpdated() {
      this.$emit('examUpdated')
      this.modalOpen = false
    }
  }
}
</script>
